// Fonts
// @import url('https://fonts.googleapis.com/css?family=Poppins:300,400');
@import url("https://use.typekit.net/itn8qod.css");

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import './fontawesome/fontawesome.scss';
@import './fontawesome/solid.scss';
@import './fontawesome/brands.scss';

.circle {
    border-radius: 50%;
}

@media (max-width:991px) {
    .dropdown-menu {
        display: block;
        border: none !important;
        background: transparent !important;
    }

}

@media (min-width:991px) {
    .dropdown>.dropdown-menu {
        visibility: hidden;
        display: block;
        opacity: 0;
        margin-top: -15px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .dropdown:hover>.dropdown-menu {
        visibility: visible;
        opacity: 1;
        display: block;
        margin-top: 0px;
    }

    .dropdown-menu {
        position: relative;
        background: #fff;
        border: 1px solid #cccccc;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    .dropdown-menu:after,
    .dropdown-menu:before {
        bottom: 100%;
        left: 15%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .dropdown-menu:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 10px;
        margin-left: -10px;
    }

    .dropdown-menu:before {
        border-color: rgba(204, 204, 204, 0);
        border-bottom-color: #cccccc;
        border-width: 11px;
        margin-left: -11px;
    }
}

.pac-container {
    z-index: 1000000 !important;
}

.logo {
    min-width: 210px;
    min-height: 80px;
    background-image: url('/svg/cps.svg');
    background-repeat: no-repeat;
    display: block;
}

.main-nav {
    background: #fff;
    z-index: 10;

    border-bottom: 5px solid $blue;

    a.nav-link {
        font-size: 120%;
        border-bottom: 2px solid transparent;
    }

    a.nav-link:hover,
    a.nav-link:focus {
        color: $blue !important;
        // font-weight:bold;
        border-bottom: 2px solid $blue;
    }
}

.sc-nav {
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;

    a {
        color: rgb(255, 255, 255) !important;
        font-size: 130%;
        border-bottom: 2px solid transparent;
    }

    a.nav-link:hover,
    a.nav-link:focus {
        color: $white !important;
        border-bottom: 2px solid $white;
    }
}

header {
    position: relative;
    background-color: black;
    // height: 100vh;
    width: 100%;
    overflow: hidden;
    background: url('/images/sc-banner.jpg') black no-repeat center center scroll;
}

header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

header .map {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}

header .container,
header .container-fluid {
    position: relative;
    z-index: 2;
}

header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $blue;
    opacity: 0.7;
    z-index: 1;
}

header .banner {
    color: #fff;
    text-align: center;
    font-size: 150%;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);

    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2rem;
    }

    p {
        font-size: 1rem;
    }
}

@media (pointer: coarse) and (hover: none) {
    header video {
        display: none;
    }

    header .banner {
        color: #fff;
        text-align: center;
        font-size: 150%;
        text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);

        h1 {
            font-size: 2rem;
        }

        h2 {
            font-size: 1.5rem;
        }
    }
}

.pr-logos {
    background: #fff;
}

.text-underline {
    text-decoration: underline;
}

.count-element {
    display: inline-block;
    background: #fff;
    font-size: 36px;
    text-align: center;
    width: 65px;
    height: 65px;
    padding: 0;
    margin: 0;
    border: 5px solid $blue;
    border-radius: 65px;
    line-height: 57px;
}

.liveReg {
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding: 30px;
    z-index: 150;
    width: 100%;
}

.liveRegBubble {
    overflow: hidden;
    width: 450px;
    height: 80px;
    border-radius: 150px;
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, .2);

    img {
        border-radius: 35px;
    }

    h5 {
        font-size: 18px;
        margin-top: 4px !important;
    }

    p {
        font-size: 12px;
        color: #666;
    }

    .time {
        font-size: 10px;
        color: #ccc;
        float: left;
    }

    .badge-container {
        font-size: 10px;
        padding-right: 30px;
        float: right;
        opacity: 0.75;
    }
}

@media (max-width: 768px) {
    .liveReg {
        padding: 0px;
    }

    .liveRegBubble {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        h5 {
            font-size: 100%;
        }

        p {
            font-size: 11px;
        }

        .badge-container {
            padding-right: 0px;
        }
    }
}

footer {
    a {
        color: #aab5bd;
    }

    a:hover {
        color: #cfd5da;
    }

    .sub-footer {
        padding: 20px 0;

        h4 {
            color: #aab5bd;
            text-transform: uppercase;
            font-size: 24px;
        }

        ul li a {
            text-transform: capitalize;
            font-size: 12px;
        }

        ul {
            margin: 0;
            padding: 0 0 0 5px;
        }

        li {
            list-style: none;
        }
    }
}

ol.tilted-list {
    list-style-type: none;
    margin: 0 0 0 3em;
    padding: 0;
    counter-reset: li-counter;
}

ol.tilted-list>li {
    background: #f5f5f5;
    border-left: 3px solid #bbb;
    padding: 1rem;
    margin: 10px 0;
    position: relative;
}

ol.tilted-list>li:before {
    position: absolute;
    top: 0;
    left: -1.2em;
    width: 1em;
    padding-right: 3px;
    font-size: 5em;
    line-height: 1;
    font-weight: 900;
    text-align: center;
    color: #407fbf;
    z-index: -99;
    overflow: hidden;
    letter-spacing: -5px;
    content: counter(li-counter);
    counter-increment: li-counter;
}

.video-container {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;

    video.video-js {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


@import "animate";

@import "download";
