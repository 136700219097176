#download-pg {
    header {
        color: #fff;
        background: url("/images/download-offer/header-bg.png") no-repeat;
        background-size: cover;
    }

    .gear {
        background: url("/images/download-offer/gear-bg.png") no-repeat;
        background-size: cover;
    }

    header.nine-reasons {
        background: url("/images/download-offer/9-reasons-bg.png") no-repeat !important;
        background-size: cover;
    }

    header.phone-objects {
        background: url("/images/download-offer/phone-objects-bg.png") no-repeat !important;
        background-size: cover;
    }

    header.fsbo {
        background: url("/images/download-offer/fsbo-bg.png") no-repeat !important;
        background-size: cover;
    }


    .blue {
        background-color: rgba(23, 73, 146, 0.1);
    }

    .text-blue {
        color: rgba(23, 73, 146, 1);
    }

    .text-orange {
        color: rgba(255, 160, 78, 1);
    }

    .orange {
        color: #fff;
        background-color: rgba(255, 160, 78, 1);
        border-color: rgba(255, 160, 78, 1);
    }

    .secondary-bg {
        background: url("/images/download-offer/secondary-bg.png") no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .grey {
        background-color: #e8e8e8;
    }
}
